//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "Error-5",
  mounted: function mounted() {},
  computed: {
    backgroundImage: function backgroundImage() {
      return process.env.BASE_URL + "media/error/bg5.jpg";
    }
  }
};